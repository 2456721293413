@import url(fontawesome.css);
@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,700,900");
body {
    font-family: 'Roboto', sans-serif;
	overflow-x: hidden;
}

#page-wraper {
    /* background: #000000 no-repeat center center fixed; */
	background-image: url("plain-smooth-maroon-paper-background.jpg");
    /* -webkit-background-size: cover; */
    /* -moz-background-size: cover; */
    /* -o-background-size: cover; */
    /* background-size: cover; */
    width: 100%;
}


/* Basic */


input,
select,
textarea {
    outline: none;
}

a {
	color: #9FF;
    text-decoration: none;
}

a:hover {
	color: #FF0;
	text-decoration: none;
}

p {
	letter-spacing: 0.5px;
    line-height: 2em;
    color: #fff;
}

h4 {
    font-size: 28px;
    font-weight: 700;
    color: #ccca3b;
    letter-spacing: 0.5px;
}

img {
    width: 100%;
    overflow: hidden;
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

ul li {
    display: inline-block;
}

html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
}

.clearfix {
    zoom: 1;
}

.clearfix:before,
.clearfix:after {
    content: "";
    display: table;
}

.clearfix:after {
    clear: both;
}

.container {
    margin: 0 auto;
}

.top-header {
	margin: 0;
	padding: 0;
    height: auto;
}

.main-nav {
    text-align: center;
}

.main-nav li:last-child a {
    border-bottom: 1px solid #69C;
}

.main-nav li {
    display: block;
}

.main-nav li a {
    height: 60px;
    line-height: 60px;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    color: #ccca3b;
    width: 100%;
    transition: all 0.5s;
    border-top: 1px solid #69C;
}

.main-nav li:hover a,
.main-nav li.active a {
    background: #fff;
    color: #363636;
}

/* Sidebar Menu */

/* .menu .image {
    margin-top: 5px;
} */

.menu .image img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    text-align: center;
}

.menu .author-content {
    margin: 20px 0px 40px 0px;
}

.menu .author-content h4 {
    margin-bottom: 0px;
	text-transform: uppercase;
}

.menu .author-content span {
    font-size: 12px;
    text-transform: uppercase;
    color: #ccca3b;
}

.menu .social-network {
    margin-top: 25px;
}

.menu .social-network ul li {
    margin: 0 3px;
}

.menu .social-network ul li a {
    width: 40px;
    height: 40px;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    background-color: rgba(0, 0, 0, 0.2);
    color: #ccca3b;
    font-size: 14px;
    transition: all 0.5s;
}

.menu .social-network ul li a:hover {
    background-color: #fff;
    color: #363636;
}

.menu .copyright-text {
    position: relative;
    margin-top: 60px;
}

.menu .copyright-text p {
    border-top: 1px solid rgba(250, 250, 250, 0.1);
    display: inline-block;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    font-size: 13px;
}

.menu:before,
.menu:after {
    content: "";
    display: table;
}

.menu:after {
    clear: both;
}

.menu a {
    text-decoration: none;
    color: inherit;
}

.menu {
    text-align: center;
}

.menu::-webkit-scrollbar {
    display: none;
}

.menu {
    left: 8%;
  /* -webkit-transition: -webkit-transform 233ms cubic-bezier(0, 0, 0.21, 1); */
  -webkit-overflow-scrolling: touch;
  background-color: #363636;
  box-sizing: border-box;
  height: 100vh;  
  max-height: 100vh !important;
  max-width: 80vw !important;
  min-width: 45px !important;
  outline: none;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  padding: 0;
  position: fixed !important;
  top: calc(0px);  
  width: 22%;
  will-change: transform;
  z-index: 9999 !important;
}

.menu-toggle {
  color: #ccca3b;
  cursor: pointer;
  font-size: 25px;
  position: fixed;
  right: 25px;
  top: 25px;
}

#menu-toggle {
    z-index: 999;
    cursor: pointer;
    position: fixed;
    top: 0;
    left: 0;
    color: #363636;
    background-color: #ccca3b;
    width: 66px;
    height: 66px;
	line-height: 66px;
    text-align: center;
    display: inline-block;
	font-size: 1.3em;
}

.menu-close {
  cursor: pointer;
}

#menu-close {
    cursor: pointer;
    text-align: right;
    color: #363636;
    background-color: #ccca3b;
    width: 100%;
    height: 66px;
    text-align: center;
    display: inline-block;
    line-height: 66px;
	font-size: 1.3em;
}


/* Section */

.section {
    color: #ccca3b;
    width: 55%;
    right: 10%;
    left: 35%;
    position: relative;
    min-height: 100vh;
    padding-bottom: 60px;
    border-bottom: 3px solid rgba(250, 250, 250, 0.25);
}

.section-heading {
    text-align: left;
    padding: 60px 0 40px 0;
}

.section-heading h2 {
    font-size: 32px;
    font-weight: 700;
    color: #ccca3b;
}

.section-heading .line-dec {
    max-width: 500px;
    height: 3px;
    background-color: #ccca3b;
    margin: 20px 0;
}

.section-heading span {
    margin-top: 5px;
    font-size: 14px;
    color: #fff;
    letter-spacing: 1px;
}

/* Buttons */

.white-button a {
    display: inline-block;
    padding: 12px 22px;
    background-color: #ccca3b;
    color: #363636;
    font-size: 14px;
    font-weight: 800;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    transition: all 0.5s;
}

.white-button a:hover {
    background-color: #363636;
    color: #ccca3b;
}

button {
    display: inline-block;
    padding: 12px 22px;
    background-color: #ccca3b;
    color: #363636;
    font-size: 14px;
    font-weight: 800;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    transition: all 0.5s;
    border: none;
    outline: none;
    cursor: pointer;
}

button:hover {
    outline: none;
    background-color: #363636;
    color: #ccca3b;
}


/* Left & Right Post */

.right-image-post {
    margin-bottom: 60px;
    padding-bottom: 60px;
    border-bottom: 2px solid rgba(250, 250, 250, 0.1);
}

.left-image img,
.right-image img {
    border-radius: 5px;
}

.right-image-post h4,
.left-image-post h4 {
    margin-top: 0px;
}

.right-image-post p,
.left-image-post p {
    margin-top: 20px;
}

.right-image-post .white-button,
.left-image-post .white-button {
    margin-top: 30px;
}


/* Services */

.my-services .col-md-6 {
    padding-left: 5px;
    padding-right: 5px;
}

.service-item {
    margin-bottom: 10px;
    padding: 50px;
    background-color: rgba(250, 250, 250, 0.1);
    transition: all 0.5s;
}

.service-item h4 {
    transition: all 0.5s;
}

.service-item p {
    transition: all 0.5s;
}

.service-item:hover {
    background-color: rgba(250, 250, 250, 1);
}

.service-item:hover h4 {
    color: #1e1e1e;
}

.service-item:hover p {
    color: #4a4a4a;
}

.service-item .service-icon {
    width: 70px;
    height: 70px;
    display: inline-block;
    margin-bottom: 30px;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all 0.5s;
}

.service-item .fa {
	font-size: 72px;
	padding: 20px 0;
	transition: all 0.5s;
}
.service-item:hover .fa {
	color: #000;
}


/* My Work */


.isotope-wrapper {
    position: relative;
    width: 100%;
    margin: auto;
}

.isotope-toolbar input[type="radio"] {
    display: none;
}

.isotope-toolbar span {
    text-transform: capitalize;
    display: inline-block;
    margin-right: 25px;
    padding-bottom: 3px;
    font-size: 18px;
    font-weight: 700;
    border-bottom: 2px solid transparent;
    transform: ease-in-out 0.5s;
    cursor: pointer;
}

.isotope-toolbar input[type="radio"]:checked + span {
    color: #363636;
    border-bottom: 2px solid #363636;
}

.isotope-box {
    position: relative;
    width: 100%;
}

.isotope-toolbar {
    text-align: center;
    margin: 10px;
}

.isotope-toolbar p {
    border-top: 1px solid #ccca3b;
    padding-top: 15px;
}

.isotope-toolbar a {
    color: #ccca3b;
}

.isotope-item {
    position: relative;
    width: 31%;
    margin: 1%;
}

.isotope-item img {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.lazyload {
    background: #f7f7f7;
}

.lazyloading {
    background: #f7f7f7 url(//afarkas.github.io/lazysizes/assets/imgs/loader.gif) no-repeat center;
    min-height: 60px;
}

figure.snip1321 {
  overflow: hidden;
  text-align: center;
  -webkit-perspective: 50em;
  perspective: 50em;
}
figure.snip1321 * {
  -webkit-box-sizing: padding-box;
  box-sizing: padding-box;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
figure.snip1321 figcaption {
  top: 50%;
  left: 20px;
  right: 20px;
  position: absolute;
  opacity: 0;
  z-index: 1;
}
figure.snip1321 h4 {
    margin-top: 15px;
  margin-bottom: 5px;
  color: #232323;
}
figure.snip1321 span {
    font-size: 14px;
    font-style: italic;
    display: block;
    color: #7a7a7a;
}
figure.snip1321 i {
  position: relative!important;
  font-size: 18px;
  background-color: #363636;
  width: 40px;
  height: 40px;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  border-radius: 5px;
  color: #ccca3b;
}
figure.snip1321:after {
  background-color: #ccca3b;
  position: absolute;
  content: "";
  display: block;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  -webkit-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  opacity: 0;
}
figure.snip1321 a {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}
figure.snip1321:hover figcaption,
figure.snip1321.hover figcaption {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 1;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
figure.snip1321:hover:after,
figure.snip1321.hover:after {
  -webkit-transform: rotateX(0);
  transform: rotateX(0);
  opacity: 0.9;
}


/* Contact */

section.contact-me {
    border-bottom: none;
}

#contact input {
    background-color: rgba(250,250,250,0.1);
    border: 1px solid rgba(250,250,250,1);
    border-radius: 0px;
    margin-bottom: 30px;
    color: #ccca3b;
    font-size: 14px;
    height: 50px;
    width: 100%;
    padding-left: 15px;
}

#contact input:focus {
    outline: none;
    box-shadow: none;
	color: #ccca3b;
}

#contact textarea {
    background-color: rgba(250,250,250,0.1);
    border: 1px solid rgba(250,250,250,1);
    border-radius: 0px;
    margin-bottom: 30px;
    color: #ccca3b;
    font-size: 14px;
    height: 180px;
    max-height: 220px;
    line-height: 40px;
    width: 100%;
    padding-left: 15px;
}

#contact textarea:focus {
    outline: none;
    box-shadow: none;
}

#contact {
    text-align: center;
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccca3b;
  opacity: 0.6; /* Firefox */
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #ccca3b;
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
  color: #ccca3b;
}

@media screen and (max-width: 1680px) {
    .menu {
        left: 4%;
    }

    .section {
        color: #ccca3b;
        width: 65%;
        right: 5%;
        left: 30%;
    }
}

@media screen and (max-width: 1680px) and (min-width: 846px) { 
    .menu .social-network ul li a {
        width: 36px;
        height: 36px;
        line-height: 36px;
    }

    .menu .social-network ul li {
        margin: 0;
    }

    .menu .image {
        margin-top: 60px;
    }
}

@media screen and (max-width: 1280px) {
    .menu {
        left: 0%;
    }

    .right-image-post h4,
    .left-image-post h4 {
        margin-top: 0px;
    }

    .right-image-post p,
    .left-image-post p {
        margin-top: 15px;
    }

    .right-image-post .white-button,
    .left-image-post .white-button {
        margin-top: 25px;
    }

}

.menu .container {
    padding-left: 0;
    padding-right: 0;
}

@media screen and (max-width: 1280px) and (min-width: 846px) {
    .menu {
        width: 25%;
    }
}

#menu-toggle,
#menu-close {
    display: none;
}  

@media screen and (max-width: 845px) {        
    #menu-toggle,
    #menu-close {
        display: inline-block;
    }    

    .menu {        
        width: 320px;
        -webkit-transform: translateX(-100%) !important;
        transform: translateX(-100%) !important;
        transition: -webkit-transform 233ms cubic-bezier(0, 0, 0.21, 1);
        transition: transform 233ms cubic-bezier(0, 0, 0.21, 1);
        transition: transform 233ms cubic-bezier(0, 0, 0.21, 1), -webkit-transform 233ms cubic-bezier(0, 0, 0.21, 1);
    }

    .menu .image {
        margin-top: 40px;        
    }

    .menu .image img {
        width: 120px;
        height: 120px;
    }

    .menu .author-content {
        margin: 20px 0px 40px 0px;
    }

    .menu .container {
        padding-left: 15px;
        padding-right: 15px;
    }

    .menu.open {
        display: inline-block;
        -webkit-transform: translateX(0) !important;
                transform: translateX(0) !important;
    }

    .responsive-nav {
        display: inline-block;
    }
    .section {
        width: 94%;
        right: 3%;
        left: 3%;
    }
    .isotope-item {
        width: 94%;
        margin: 3%;
    }
    .left-image-post img {
        margin-bottom: 30px;
    }
    .right-image-post img {
        margin-top: 30px;
    }
    .isotope-item {
        border-bottom: none;
        text-align: center;
    }
    .isotope-toolbar {
        margin-bottom: 20px;
    }
}